<template>
  <div>
    <c-table
      ref="table"
      :title="'\'' + popupParam.requestContents + '\'에 대한 개선요청 목록'"
      tableId="imprRequest"
      :columns="grid.columns"
      :data="grid.data"
      :plantCd="popupParam.plantCd"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      selection="multiple"
      rowKey="sopImprovementId"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='src'">
          <q-btn 
            round unelevated 
            size="6px"
            color="amber" 
            icon="search"
            @click="srcClick(props.row, props.pageIndex)" />
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="Excel 업로드" icon="add" @btnClicked="openExcelUploader" />
          <c-btn v-if="editable" label="조치업체 추가" icon="add" @btnClicked="addImprActionDept" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="개선요청 저장" 
            icon="save"
            @beforeAction="saveImprRequest"
            @btnCallback="saveCallback" />
          <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-request',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          requestContents: '',
          ibmTaskTypeCd: '',
          plantCd: '',
          processCd: '',
          sopMapId: '',
          approvalUserId: '',
          ibmTitle: '',
          row: {
            riskOccurrenceCause: '',
            riskHazardClassCd: '',
            sopName: '',
            processName: '',
          }
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      title: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.saveUrl = transactionConfig.sop.ibm.improve.insert.url
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      if (this.popupParam.ibmTaskTypeCd === 'ITT0000005') {
        this.grid.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'src',
            field: 'src',
            label: '위치정보',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선담당',
            align: 'center',
            child: [
              {
                name: 'actionDeptName',
                field: 'actionDeptName',
                label: '개선담당업체',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
              {
                name: 'actionUserName',
                field: 'actionUserName',
                label: '개선담당자',
                userId: 'actionUserId',
                type: 'user',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
            ]
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
            required: true,
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료예정일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'date',
            required: true,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:280px',
            sortable: false,
            type: 'text',
            required: true,
          },
        ];
      } else if (this.popupParam.ibmTaskTypeCd === 'ITT0000001') {
        this.grid.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'src',
            field: 'src',
            label: '위치정보',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선담당',
            align: 'center',
            child: [
              {
                name: 'actionDeptName',
                field: 'actionDeptName',
                label: '개선담당업체',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
              {
                name: 'actionUserName',
                field: 'actionUserName',
                label: '개선담당자',
                userId: 'actionUserId',
                type: 'user',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
            ]
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
            required: true,
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료예정일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'date',
            required: true,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:280px',
            sortable: false,
            type: 'text',
            required: true,
          },
        ];
      } else {
        this.grid.columns = [
          {
            name: 'impr',
            field: 'impr',
            label: '개선담당',
            align: 'center',
            child: [
              {
                name: 'actionDeptName',
                field: 'actionDeptName',
                label: '개선담당업체',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
              {
                name: 'actionUserName',
                field: 'actionUserName',
                label: '개선담당자',
                userId: 'actionUserId',
                type: 'user',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                required: true,
              },
              // {
              //   name: 'approveUserName',
              //   field: 'approveUserName',
              //   label: '개선승인자',
              //   userId: 'approveUserId',
              //   isVendorOrg: true,
              //   type: 'user',
              //   align: 'center',
              //   style: 'width:120px',
              //   sortable: false,
              //   required: true,
              // },
            ]
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
            required: true,
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료예정일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'date',
            required: true,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:280px',
            sortable: false,
            type: 'text',
            required: true,
          },
        ];
      }
    },
    /* eslint-disable no-unused-vars */
    openExcelUploader() {
      this.popupOptions.title = '개선요청 엑셀 업로드'; // 개선요청 엑셀 업로드
      this.popupOptions.param = this.popupParam;
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequestUpload.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelPopup;
    },
    closeExcelPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.relationTableKey = this.popupParam.relationTableKey;
          item.plantCd = this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd
          item.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          item.ibmStepCd = 'IS00000001';
          item.ibmClassCd = 'IC00000001';
          item.improveRequestDeptCd = this.$store.getters.user.selfVendorCd;
          item.improveRequestDeptName = this.$store.getters.user.selfVendorName;
          item.improveRequestUserId = this.$store.getters.user.userId;
          item.improveRequestUserName = this.$store.getters.user.userName;
          item.ibmTitle =  this.popupParam.ibmTitle,
          // 공종, 작업, 위치정보 자동표시
          item.processName = this.popupParam.row.processName;
          item.sopName = this.popupParam.row.sopName;
          item.maps = this.popupParam.row.maps;
          item.companyCd = this.$store.getters.user.companyCd,
          // 점검자
          item.regUserId = this.popupParam.row.assessUserId;
          // 승인자
          item.approveUserId = this.popupParam.approvalUserId;
          item.companyCd= this.$store.getters.user.companyCd
          item.regDt = this.$comm.getToday();
          item.editFlag = 'C';
        })
        this.grid.data = this.$_.concat(this.grid.data, s_data)
      }
    },
    addImprActionDept() {
      this.popupOptions.title = '조치업체';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd,
        processCd: this.popupParam.processCd,
      }
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          // if (this.$_.findIndex(this.grid.data, { actionDeptCd: item.deptCd }) === -1) {
          this.grid.data.push({
            relationTableKey : this.popupParam.relationTableKey,
            plantCd: this.popupParam.plantCd ? this.popupParam.plantCd : this.$store.getters.user.plantCd,
            ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
            ibmStepCd: 'IS00000001',
            ibmClassCd: 'IC00000001',
            // 요청업체
            improveRequestDeptCd: this.$store.getters.user.selfVendorCd,
            improveRequestDeptName: this.$store.getters.user.selfVendorName,
            // 요청자
            improveRequestUserId: this.$store.getters.user.userId,
            improveRequestUserName: this.$store.getters.user.userName,
            improveRequestName: this.$store.getters.user.selfVendorName + '/' +
              this.$store.getters.user.userName,
            actionDeptCd: item.vendorCd,
            actionDeptName: item.vendorName,
            // 사용자 선택
            vendorCd: item.vendorCd,
            // 공종, 작업, 위치정보 자동표시
            processName: this.popupParam.row.processName,
            sopName: this.popupParam.row.sopName,
            maps: this.popupParam.row.maps,
            companyFlag: item.companyFlag,
            improveRequestContents: '',
            actionCompleteRequestDate: this.$comm.getToday(),
            sopImprovementId: uid(),
            ibmTitle: this.popupParam.ibmTitle,
            // 승인자
            approveUserId: this.popupParam.approvalUserId,
            companyCd: this.$store.getters.user.companyCd,
            regDt: this.$comm.getToday(),
            editFlag: 'C',
          })
          // }
        })
      }
    },
    saveImprRequest() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '목록에 표시된 개선요청을 \'' + this.popupParam.requestContents + '\'항목에 대한 개선건으로 추가합니다.\n\r 저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.ibmStepCd = 'IS00000001'
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        relationTableKey: this.popupParam.relationTableKey,
        ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
      }
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('closePopup', _result.data);
      },);
    },
    removeImpr() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data.splice(this.$_.findIndex(this.grid.data, { sopImprovementId: item.sopImprovementId }), 1)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
        
      }
    },
    srcClick(row) {
      this.row = row;
      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.relationTableKey,
        maps: row.maps ? row.maps : [],
        sopMapId: this.popupParam.sopMapId,
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
