var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title:
              "'" + _vm.popupParam.requestContents + "'에 대한 개선요청 목록",
            tableId: "imprRequest",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            plantCd: _vm.popupParam.plantCd,
            gridHeight: _vm.grid.height,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            selection: "multiple",
            rowKey: "sopImprovementId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "src"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            round: "",
                            unelevated: "",
                            size: "6px",
                            color: "amber",
                            icon: "search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.srcClick(props.row, props.pageIndex)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "Excel 업로드", icon: "add" },
                        on: { btnClicked: _vm.openExcelUploader },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "조치업체 추가", icon: "add" },
                        on: { btnClicked: _vm.addImprActionDept },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.data,
                          mappingType: "POST",
                          label: "개선요청 저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImprRequest,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }